.popup-menu {
  .adm-button {
    padding: 14px 20px;
    font-weight: 400;
    text-align: left;
    border-radius: 0;

    &::before {
      border-radius: inherit;
    }

    span {
      justify-content: flex-start;
    }

    .svg-icon {
      margin-right: 20px;
    }
  }
}
