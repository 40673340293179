.title {
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
}

.title-1 {
  font-size: 32px;
  font-weight: 500;
}

.title-2 {
  font-size: 24px;
}

.title-3 {
  font-size: 20px;
}

.title-4 {
  font-size: 18px;
}

.title-5 {
  font-size: 16px;
}

.title-6 {
  font-size: 15px;
}

.title-bold {
  font-weight: 500;
}
