.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 15% 10px 15px;
  color: inherit;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &.is-list {
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 20px 5px;
    height: 66px;

    .category-card {
      &__icon {
        position: relative;
        top: -3px;
        margin-right: 15px;
        margin-bottom: 0;
        width: 70px;
        height: calc(100% - 10px);
        min-height: 0;

        img {
          max-width: 100%;
          max-height: 100%;
          pointer-events: none;
        }
      }
    }

    .title {
      margin-bottom: 0 !important;
    }

    .text {
      position: relative;
      top: 1px;
      margin-left: 10px;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: auto;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    min-height: 60px;

    img {
      max-height: 56px;
    }
  }
}

[draggable="true"] {
  //transform: translate(0, 0);
  //transition: transform 0.25s;

  &.dragging {
    transition: none;
  }
}
.drag {
  position: relative;
  z-index: 100;
}
.drag a {
  pointer-events: none;
}

[href="/category/6cb7c59d-92c4-452c-8a0e-2437604b70f3"] {
  transition: transform 0.3s;
}
