.navbar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 8px 13px;
  background-color: #fff;
  transition: box-shadow 0.2s;
}

.aside {
  flex-shrink: 0;
  margin-left: auto;
}
