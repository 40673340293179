.create-file {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 134px;
    height: 5px;
    background: #fff;
    border-radius: 100px;
    transform: translateX(-50%);
  }

  img {
    width: 100%;
  }

  &__save {
    position: absolute;
    right: 0;
    bottom: 40px;
    z-index: 6;
  }

  .adm-button {
    padding: 3px 18px;
  }
}

.react-html5-camera-photo {
  //position: fixed;
  //top: 0;
  //left: 50%;
  //z-index: 5;
  width: 100%;
  height: 100%;
  //max-width: 375px;
  //transform: translateX(-50%);

  #outer-circle {
    cursor: pointer;
  }
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#container-circles {
  bottom: 120px;
}

.react-html5-camera-photo #outer-circle {
  left: -30px;
  padding: 3px;
  width: 60px;
  height: 60px;
  border: 6px solid #fff;
  background-color: transparent;
}

.react-html5-camera-photo #inner-circle {
  position: static;
  margin: 0;
  width: 100%;
  height: 100%;
}
