.pro-version-notify {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-radius: 20px;

  &__icon {
    flex-shrink: 0;
    margin-right: 16px;
  }

  &__content {
    flex: 1 1 auto;
    min-width: 0;
  }

  &__label {
    margin-bottom: 3px;
    font-size: 12px;
  }

  &__button {
    flex-shrink: 0;
    margin-left: 15px;
  }
}
