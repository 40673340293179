.text {
  margin: 0;
  font-size: 12px;
  line-height: 1.2;
}

.text-primary {
  color: var(--adm-color-text);
}

.text-sm {
  font-size: 10px;
}

.text-lg {
  font-size: var(--adm-font-size-8);
  line-height: 1.25;
}

.text-secondary {
  color: var(--adm-color-text-secondary);
}

.text-bold {
  font-weight: 500;
}
