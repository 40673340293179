.layout {
  padding-bottom: 120px;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin: -88px 0 40px;
  width: 100%;
  height: 135px;
  overflow: hidden;

  &Bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    clip-path: path('M386.416 -193.004C384.972 -200.363 382.425 -207.416 382.228 -215.003C370.804 -216.347 360.235 -216.705 348.702 -216.678C336.653 -216.678 317.801 -220.375 306.196 -223.712C222.166 -226.454 232.463 -217.072 195.622 -215.939C176.011 -215.313 156.339 -220.779 136.837 -218.081C135.955 -223.251 134.71 -247.136 129.987 -249C119.82 -247.544 105.098 -248.072 94.8574 -249C85.6045 -249 76.7161 -244.14 67.459 -244.482C53.4176 -244.98 43.3066 -245.628 29.3859 -247.429C16.5966 -246.245 0.75621 -245.51 -11 -249C-7.97192 -224.83 -1.47129 -199.642 -11 -175.76C-10.0392 -164.463 -3.25437 -133.032 -2.27187 -121.681C0.709167 -87.0732 -7.51332 -69.2882 -10.6293 -34.8442C-10.6293 -8.1738 -2.27187 -0.992935 -9.46699 41.7005C-9.78354 68.1087 -7.74848 94.7851 -5.76306 121.104C57.4617 112.792 75.3248 131.661 130.973 122.348C163.353 116.929 202.333 122.496 214.018 124.511C225.569 121.771 247.217 119.768 258.412 124.918C271.301 123.209 303.541 118.782 316.535 119.092C345.551 119.729 354.732 119.866 383.761 122.181C386.153 92.899 374.284 67.9716 381.343 33.4893C385.958 10.9432 375.094 -20.7425 381.343 -47.4114C389.734 -83.2199 375.949 -91.1225 381.934 -129.302C385.228 -150.114 390.726 -171.892 386.416 -193.004Z');
  }
}

.scan {
  position: fixed;
  left: 50%;
  bottom: 35px;
  z-index: 5;
  text-align: center;
  transform: translateX(-50%);
}
