.list {
  margin: 40px 0 0;
  padding-left: 0;
  display: inline-block;
  font-size: 20px;
  text-align: left;
  list-style: none;

  li {
    position: relative;
    padding-left: 40px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 24px;
      background-image: url('data:image/svg+xml,%3Csvg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="12.5" cy="12" r="12" fill="%2302AAFF"/%3E%3Cpath d="M8.5 12L11.5 16L16.5 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
    }
  }
}
