.search-form {
  display: flex;
  align-items: center;

  &__field {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;

    &-icon {
      position: absolute;
      top: 8px;
      left: 8px;
      color: #888C9B;
    }

    &-reset {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      width: 38px;
      height: 100%;
      border: 0;
      outline: 0;
      background: none;
    }
  }

  &__input {
    padding: 0 38px 0 30px;
    width: 100%;
    height: 36px;
    border: 0;
    outline: 0;
    color: #20263E;
    font-size: 16px;
    background: rgba(32, 38, 62, 0.1);
    border-radius: 10px;

    &::placeholder {
      color: #888C9B;
    }
  }

  &__cancel {
    flex-shrink: 0;
    margin-left: 4px;
  }
}
