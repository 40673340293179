.folder-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.is-list {
    flex-direction: row;

    .folder-card {
      &__link {
        flex: 1 1 auto;
        min-width: 0;
        flex-direction: row;
      }

      &__icon {
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: 20px;
        width: 32px;
        height: 28px;
        clip-path: path('M31.873 4.19222C31.7572 3.64129 31.5529 3.11327 31.5371 2.54523C30.6209 2.44465 29.7733 2.41778 28.8483 2.41985C27.8819 2.41985 26.37 2.14304 25.4393 1.89322C18.7 1.68794 19.5259 2.39037 16.5712 2.47519C14.9983 2.52201 13.4207 2.11283 11.8566 2.31479C11.7859 1.92772 11.686 0.139577 11.3072 0C10.4918 0.108995 9.3111 0.0694863 8.48981 0C7.74773 0 7.03488 0.363869 6.29245 0.338265C5.16632 0.30098 4.35541 0.252447 3.23897 0.117619C2.21326 0.206262 0.942854 0.261285 0 0C0.242854 1.80955 0.764207 3.69522 0 5.48317C0.0770604 6.32895 0.621203 8.68212 0.7 9.53191C0.939081 12.1229 0.279634 13.4544 0.0297325 16.0331C0.0297325 18.0298 0.7 18.5674 0.122948 21.7637C0.0975608 23.7408 0.260773 25.7379 0.420005 27.7083C5.49066 27.0861 6.9233 28.4987 11.3863 27.8015C13.9832 27.3958 17.1094 27.8125 18.0466 27.9634C18.973 27.7583 20.7092 27.6083 21.607 27.9939C22.6406 27.8659 25.2263 27.5345 26.2685 27.5577C28.5956 27.6053 29.3319 27.6157 31.66 27.7889C31.8519 25.5967 30.9 23.7305 31.4661 21.1489C31.8363 19.461 30.9649 17.0888 31.4661 15.0922C32.1391 12.4113 31.0335 11.8197 31.5135 8.96132C31.7777 7.40326 32.2186 5.77277 31.873 4.19222Z');
      }

      &__more {
        flex-shrink: 0;
      }
    }

    .preview {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    text-align: center;
    text-decoration: none;
  }

  &__more {
    margin-top: 2px;
    padding: 4px;
    cursor: pointer;
  }

  .preview {
    margin-bottom: 12px;
  }
}
