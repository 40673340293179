@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

:root {
  --adm-color-primary: #02AAFF;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #FF4369;

  --adm-color-white: #ffffff;
  --adm-color-text: #20263E;
  --adm-color-text-secondary: #888C9B;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #ffffff;
  --adm-font-size-1: 12px;
  --adm-font-size-5: 16px;
  --adm-font-size-9: var(--adm-font-size-5);
  --adm-font-size-10: 18px;

  --adm-font-size-main: 15px;

  --adm-font-family: 'Rubik', sans-serif;

  --adm-button-border-radius: 40px;
  --adm-center-popup-border-radius: 30px;
  --adm-center-popup-max-width: 255px;
}

* {
  user-select: none;
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  max-width: 375px;
  min-width: 320px;
  overflow-x: hidden !important;
}

input, textarea, button {
  font-family: inherit;
}

img, svg {
  vertical-align: middle;
}

strong {
  font-weight: 500;
}

.adm-form {
  border-top: 0;
  border-bottom: 0;
}

.adm-list-default .adm-list-body {
  border-top: 0;
  border-bottom: 0;
}

.adm-form-item-label .adm-form-item-required-asterisk {
  display: none;
}

.adm-popup-body {
  padding: 30px 0 30px;
  border-radius: 30px 30px 0 0;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    width: 40px;
    height: 3px;
    background: rgba(32, 38, 62, 0.2);
    border-radius: 6px;
    transform: translateX(-50%);
  }
}

.adm-popup-confirm-delete {
  .adm-modal-button-primary {
    background-color: #FF4369;
    border-color: #FF4369;
  }
}

.adm-button {
  //font-weight: 500;

  &.adm-button-default.adm-button-fill-none {
    font-weight: 400;
  }

  &.adm-button-shape-rounded {
    span {
      .svg-icon {
        margin-right: 0;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      position: relative;
      top: -1px;
      margin-right: 8px;
    }
  }
}

.adm-modal {
  text-align: center;

  .adm-list-item {
    padding-left: 0;
  }

  .adm-list-item-content {
    padding-right: 0;
  }

  .adm-list-item-content-main {
    padding: 0;
  }

  .adm-input-element {
    height: 50px;
    text-align: inherit;
    border-bottom: 1px solid rgba(32, 38, 62, 0.2);

    &::placeholder {
      font-weight: 400;
    }
  }
}

.adm-modal-header {
  padding: 0 20px;
}

.adm-modal-title {
  padding: 0 20px;
}

.adm-modal-body:not(.adm-modal-with-image) {
  padding-top: 30px;
}

.adm-modal-content {
  padding: 0 20px 10px;
}

.adm-modal-footer {
  padding: 20px 20px 30px;
}

.page {
  &__background {
    position: absolute;
    top: -34px;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    transition: background-color 0.3s;

    svg {
      width: 100%;
    }
  }
}

.adm-space {
  --gap-vertical: 30px;
}

.adm-toast-mask.login-error .adm-toast-main {
  top: 10% !important;
}

.adm-action-sheet-extra {
  margin-bottom: 15px;
  color: inherit;
  justify-content: flex-start;
  padding: 0 25px;
  border-bottom: 0;

  .preview {
    position: relative;
    top: -1px;
    margin-right: 20px;
  }
}

.adm-action-sheet-button-list {
  border-bottom: 0;
}

.adm-action-sheet-button-item-wrapper {
  border-bottom: 0;
}

.adm-action-sheet-button-item {
  padding: 15px 25px;
  text-align: left;

  svg {
    margin-right: 20px;
  }

  span {
    position: relative;
    top: 2px;
  }
}


.adm-action-sheet-popup > .adm-popup-body {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

//.move-to {
//  .preview {
//    svg path {
//      fill: currentColor;
//    }
//  }
//
//  .title {
//    color: var(--adm-color-text);
//  }
//}

.adm-button.adm-button-large {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 500;
}

.adm-button.adm-button-small {
  font-size: var(--adm-font-size-4);
}

.adm-badge {
  --right: 50%;
}

.adm-badge-content {
  padding: 3px 8px;
  font-weight: 500;
}

.adm-badge-bordered {
  background-color: var(--adm-color-white);
  border-color: var(--adm-color-primary);

  .adm-badge-content {
    color: var(--adm-color-text);
  }
}

.adm-popup-body-position-bottom .adm-popup-close-icon {
  right: 20px;
  top: 20px;
}

.adm-popup-close-icon {
  background: rgba(32, 38, 62, 0.1);
  border-radius: 50%;
}

