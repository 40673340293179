.root {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-align: left;
  //border: 1.5px solid var(--adm-color-white);
  border: 1.5px solid rgba(32, 38, 62, 0.1);
  cursor: pointer;
  border-radius: 20px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  p {
    margin-top: 3px;
  }
}

.content {
  flex: 1 1 auto;
}

.aside {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: 15px;

  svg {
    margin-left: 15px;
  }
}
